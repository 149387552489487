@charset "UTF-8";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap";
@import 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap';
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', 'Times New Roman', serif; }

body,
a,
p,
li,
td {
  font-family: 'Noto Sans TC', '微軟正黑體', sans-serif; }

.banner_section {
  font-size: 100px;
  width: 100%;
  margin-top: 66px;
  overflow: hidden;
  background: linear-gradient(135deg, #EBF9F1 14.44%, #C8DED2 82.12%, #8BA999 202.78%); }
  .banner_section .inner_container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .banner_section .inner_container > div {
      position: relative;
      padding-top: 0.32em; }
    .banner_section .inner_container > div:nth-child(1) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
      flex: 0 0 45%; }
    .banner_section .inner_container > div:nth-child(2) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 55%;
      flex: 0 0 55%; }
  .banner_section h1 {
    font-size: 100px;
    line-height: 0px;
    margin-top: 0;
    margin-bottom: 0.3em; }
  .banner_section img {
    width: 100%; }
  .banner_section .slogan_1 {
    width: 3.81em;
    margin-left: auto;
    margin-right: auto; }
  .banner_section .slogan_2 {
    width: 2.04em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0.1em; }
  .banner_section .slogan_3 {
    width: 2.73em;
    margin-left: auto;
    position: relative;
    top: 0.2em;
    right: 0.8em; }
  .banner_section .flower_l {
    width: 1.41em;
    position: absolute;
    left: 0.3em;
    bottom: 0px; }
  .banner_section .flower_r {
    width: 0.7em;
    position: absolute;
    top: 0.2em;
    right: 0.6em; }
  .banner_section .flower_door {
    width: 6.66em;
    position: relative;
    bottom: -0.3em; }
  .banner_section .img_woman {
    width: 3.99em;
    position: absolute;
    left: 20%;
    bottom: -0.24em; }

@media only screen and (max-width: 1199px) {
  .banner_section {
    font-size: 80px; }
    .banner_section h1 {
      font-size: 80px; } }

@media only screen and (max-width: 991px) {
  .banner_section .inner_container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .banner_section .inner_container > div:nth-child(1) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
      flex: 0 0 60%;
      margin-top: 0.5em; }
    .banner_section .inner_container > div:nth-child(2) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
  .banner_section h1 {
    font-size: 70px; }
  .banner_section .img_woman {
    left: 28%; } }

@media only screen and (max-width: 767px) {
  .banner_section {
    margin-top: 60px; }
    .banner_section .inner_container > div {
      padding-top: 0; }
    .banner_section .inner_container > div:nth-child(1) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      margin-top: 0.5em; }
    .banner_section .flower_door {
      left: 0.125em; }
    .banner_section .img_woman {
      left: 1.5em; } }

section {
  width: 100%; }
  section.sec_container_1 {
    background-color: #FFFDF3; }
  section.sec_container_2 {
    background-color: #337B87; }
  section.sec_container_4 {
    background: linear-gradient(147deg, #EBF9F1 32.71%, rgba(110, 193, 188, 0.5) 134.3%); }
  section .inner_container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-bottom: 70px; }
    section .inner_container p {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      color: #005665;
      font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.6px; }
  section .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px; }
  section .service_col {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px; }
    section .service_col img {
      width: 100%;
      max-width: 200px; }
  section.sec_container_3 .row {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
  section .act_col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%; }
    section .act_col .gap {
      padding-left: 18px;
      padding-right: 18px; }
    section .act_col h4 {
      margin-top: 0;
      margin-bottom: 16px;
      border-left: 4px solid #6EC1BC;
      color: #005665;
      font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.96px;
      padding-left: 16px; }
      section .act_col h4 > span {
        display: block;
        font-family: 'Playfair Display', 'Noto Sans TC', '微軟正黑體', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.48px;
        margin-top: 8px; }
    section .act_col .content {
      padding-left: 20px; }
    section .act_col ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none; }
      section .act_col ul > li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start; }
      section .act_col ul p.title {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
      section .act_col ul p.txt {
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
    section .act_col p {
      text-align: left;
      color: #303C3D;
      font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.54px; }
  section .act_col.title_img {
    margin-top: 20px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%; }
    section .act_col.title_img .gap {
      padding-left: 30px;
      padding-right: 30px; }
      section .act_col.title_img .gap > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
    section .act_col.title_img img {
      max-width: 220px;
      width: 100%; }
  section.sec_container_4 .inner_container {
    padding-bottom: 40px; }
    section.sec_container_4 .inner_container .row > div:nth-child(1) {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 48%;
      flex: 0 1 48%; }
    section.sec_container_4 .inner_container .row > div:nth-child(2) {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 52%;
      flex: 0 1 52%;
      padding-left: 40px;
      padding-right: 40px; }
  section .about_col h3 {
    margin-top: 0;
    margin-bottom: 24px;
    color: #005665;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
    letter-spacing: 0.96px; }
  section .about_col p {
    margin-top: 0;
    margin-bottom: 16px;
    color: #005665;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px; }
  section .about_col a.ico_social {
    display: block;
    text-decoration: none;
    margin-top: 40px;
    width: 48px; }
  section .about_col img {
    width: 100%; }

@media only screen and (max-width: 991px) {
  section.sec_container_3 .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    section.sec_container_3 .row .act_col {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%; }
    section.sec_container_3 .row .act_col.title_img {
      margin-top: 0px;
      margin-bottom: 40px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  section.sec_container_4 .inner_container .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    section.sec_container_4 .inner_container .row > div:nth-child(1) {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 80%;
      flex: 0 1 80%; }
    section.sec_container_4 .inner_container .row > div:nth-child(2) {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
      flex: 0 1 100%;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px; } }

@media only screen and (max-width: 767px) {
  section.sec_container_2 .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    section.sec_container_2 .row .service_col {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
      flex: 0 1 100%;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px; }
  section.sec_container_3 .row .act_col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-bottom: 40px; }
    section.sec_container_3 .row .act_col:last-child {
      margin-bottom: 0; }
  section.sec_container_4 .inner_container > div:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%; } }

body {
  position: relative;
  margin: 0;
  padding: 0; }

header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 66px;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12); }
  header ul.menu_list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    header ul.menu_list li {
      text-align: center; }
    header ul.menu_list a {
      display: block;
      min-width: 90px;
      padding: 10px;
      text-decoration: none;
      color: #005665;
      font-family: 'Playfair Display', 'Times New Roman', serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.54px; }
      header ul.menu_list a > img {
        margin-top: 20px;
        width: 194px;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(2, 91, 107, 0.16);
        box-shadow: 0px 0px 8px 0px rgba(2, 91, 107, 0.16); }
  header a.logo.mobile_logo {
    display: none; }
  header ul.mobile_menu {
    display: none; }
  @media only screen and (max-width: 767px) {
    header {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 60px; }
      header a.logo.mobile_logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 20px; }
        header a.logo.mobile_logo > img {
          width: 150px; }
      header ul.menu_list {
        width: 100%;
        position: absolute;
        top: 60px;
        right: -300%;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
        background-color: #FFFDF3;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        header ul.menu_list > li {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
        header ul.menu_list > li:nth-child(3) {
          display: none; }
        header ul.menu_list a {
          padding: 24px 10px;
          font-size: 24px; }
      header ul.menu_list.active {
        right: 0;
        -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12); }
      header ul.mobile_menu {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        display: block;
        cursor: pointer;
        position: relative;
        width: 24px;
        height: 24px;
        padding: 10px 20px; }
        header ul.mobile_menu > li {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 24px;
          height: 3px;
          border-radius: 2px;
          background: #6FA3AB;
          -webkit-transition: all 0.3s;
          transition: all 0.3s; }
        header ul.mobile_menu > li:nth-child(1) {
          top: 30%; }
        header ul.mobile_menu > li:nth-child(3) {
          top: 70%;
          width: 16px; }
      header ul.mobile_menu.active > li:nth-child(1) {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg); }
      header ul.mobile_menu.active > li:nth-child(2) {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg); }
      header ul.mobile_menu.active > li:nth-child(3) {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg); } }

footer {
  width: 100%;
  background-color: #005665;
  padding-top: 12px;
  padding-bottom: 12px; }
  footer p {
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0; }
  footer a {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  footer a:hover {
    color: #005665; }
  @media only screen and (max-width: 767px) {
    footer p > span {
      display: block; } }

a.primary_btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 40px;
  background: #EBF9F1;
  border: 1px solid #7FAFB5;
  padding: 18px 32px;
  color: #005665;
  font-family: 'Noto Sans TC', '微軟正黑體', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1.8px;
  text-decoration: underline;
  -webkit-transition: all 0.6s;
  transition: all 0.6s; }
  a.primary_btn:before, a.primary_btn:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-image: url(../img/ico_star.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  a.primary_btn:before {
    margin-right: 10px; }
  a.primary_btn:after {
    margin-left: 10px; }
  a.primary_btn:hover {
    background: #FFFAE0;
    border: 1px solid #7FAFB5;
    -webkit-box-shadow: 2px 2px 12px 0px rgba(0, 86, 101, 0.12);
    box-shadow: 2px 2px 12px 0px rgba(0, 86, 101, 0.12); }
